import React from 'react'
import './Footer.css';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div>
            <nav className="navbar bottom navbar-expand text-center" style={{backgroundColor: '#131E3A'}}>
                <div className="container text-center d-flex justify-content-center">
                    <div className="row">
                        {/* <div className="col-12 d-flex justify-content-center">
                            <ul className="navbar-nav footer-padding">
                                <li className="nav-item">
                                    <a className="nav-link" href="#" style={{color: 'white'}}>HOME</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" style={{color: 'white'}}>ABOUT US</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" style={{color: 'white'}}>TEAM</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" style={{color: 'white'}}>SERVICES</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" style={{color: 'white'}}>BLOG</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" style={{color: 'white'}}>CONTACT</a>
                                </li>
                            </ul>
                        </div> */}
                        <div className="col-12 justify-content-center border-top-2 border-secondary">
                       
                        
                        <div className="footerbody" ><p><Link to="/privacy">Privacy Policy</Link></p>
                        <p> <Link to="/Terms">Terms And Condition</Link></p></div>
                       
                            <div className="copyright"><p>Copyright @ 2021. All rights reserved.</p></div>
                            
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

