import React, { useState } from 'react';
import logo from '../../Assets/verticals-logo.png'
import LandS from '../MapSearch-Section/LandS';
import './LandingPage.css'
import classNames from 'classnames'

const LandingPage = (props) => {
    const [searchInputEmpty, setSearchInputEmpty] = useState(false);
    // function handlePlumbingClick() {
    //     props.setSearchTerm([{
    //         "tag_id": 7,
    //         "tag_name": "plumber"
    //     }]);
    //     props.setRedir("/home");
    //     // console.info("plumbingggg")
    // }

    // function handleKeyClick() {
    //     props.setSearchTerm([{
    //         "tag_id": 1,
    //         "tag_name": "key"
    //     }]);
    //     props.setRedir("/home");
    // }

    let searchText = classNames('subtitle','is-size-4', 'has-text-white', {
        'search-text-shake': searchInputEmpty
    })

    return (
        <>

            <section className="hero is-fullheight landing">
                <section className="section mobile-logo">
                    <img src={logo} alt="VerticalsJM website logo" style={{ maxHeight: '3rem' }} />
                </section>
                <div className="hero-head">
                    <nav className="navbar">
                        <div className="container mr-0">
                            <div className="navbar-menu" >
                                <div className="navbar-end" style={{ height: '6rem' }}>

                                    <span className="navbar-item ">
                                        <img src={logo} alt="VerticalsJM website logo" style={{ maxHeight: '3rem' }} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="hero-body mobile-padding">
                    <div className="container has-text-centered mobile-padding" >
                        <div className="search-padding is-flex is-justify-content-center">
                            <div className="search-view pt-6 pb-6 pl-3 pr-3">
                                <h4 className={searchText}>What do you need to find today</h4>

                                {/* <input className="input mobile-landing-search" type="text" placeholder="E.g: Plumber" /> */}
                                {/* <SearchBarLanding setRedir={props.setRedir} styl={{display: 'flex', justifyContent: 'center'}} setSearch={props.setSearchTerm} /> */}
                                <LandS setRedir={props.setRedir} styl={{ display: 'flex', justifyContent: 'center' }} setSearch={props.setSearchTerm} setSearchInputEmpty={setSearchInputEmpty} />
                                {/* <div className=" pt-6 is-flex is-justify-content-space-around is-size-3 landing-icons">
                                    <button className="button landingBtn is-outlined is-medium" onClick={handlePlumbingClick}>
                                        <span className="icon">
                                        <i className="fas fa-wrench"></i>
                                        </span>
                                    </button>
                                    <button className="button landingBtn is-outlined is-medium" onClick={handleKeyClick}>
                                        <span className="icon">
                                        <i className="landing-icon fas fa-key"></i>
                                        </span>
                                    </button> */}
                                    {/* <div className="landing-btn-border" onClick={handlePlumbingClick}>
                                        <i className="landingBtn fas fa-wrench"></i>
                                    </div> */}
                                    {/* <div onClick={handleKeyClick} >
                                        <i className="landingBtn fas fa-key"></i>
                                    </div> */}

                                    {/* <i className="fas fa-wine-glass-alt"></i>
                                <i className="fas fa-tshirt "></i> */}
                                {/* </div> */}
                            </div>

                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default LandingPage;
