import React, { useEffect, useState } from 'react'
import './Header.css'

export default function Header(props) {
    const [headerDetails, setHeaderDetails] = useState({searchTerm: "", count: 0})
    useEffect(() => {
        // console.log('header details')
        // console.log(props.header)
        setHeaderDetails(props.header)
    }, [props.header])
    return (
        <div className="mb-4">
            {/* <div className="block">
                <span className="search-results-label">Search results for</span> <span className="search-results-text">{headerDetails.searchTerm}</span>
            </div> */}
            <div className="block">
                <span className="search-results-count">{headerDetails.count}</span> <span className="search-results-count-label">{headerDetails.count > 1 ? `Pros`:`Pro`} found in your area</span>
            </div>
        </div>
    )
}
