import React from 'react'
import './Results.css'
import ListingItems from './ListingItems'
// import { useMediaQuery } from 'react-responsive'
// import classNames from 'classnames'

export default function Results(props) {
    // columns is-flex is-flex-wrap-wrap is-justify-content-space-between
    // const isDesktopOrLaptop = useMediaQuery({
    //     query: '(min-device-width: 1224px)'
    // })
    // // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
    // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    // const isTabletOrMobileDevice = useMediaQuery({
    //     query: '(max-device-width: 1224px)'
    // })
    // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    // const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })


    // function isMobile() {
    //     if (!isDesktopOrLaptop && isTabletOrMobile && isTabletOrMobileDevice && (isPortrait || isLandscape)) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    // var resultsBody = classNames('columns ', {
    //     'is-multiline': isDesktopOrLaptop ? true : false,
    //     'is-flex': isMobile(),
    //     'is-flex-direction-row': isMobile(),
    //     'results-body': isDesktopOrLaptop ? true : false,
    //     'results-body-mobile': isMobile()
    // });
    return (
        <div className="results-body">
            {/* <div className={resultsBody} > */}
            {
                props.details.map((listing, index) => {
                    return <ListingItems userLocation={props.userLocation} key={listing.listing_id} details={listing} />
                })
            }       
            </div>
    )
}
