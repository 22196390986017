import React, { useState, useEffect } from 'react'
import Map from './Map'
import SearchBar from './SearchBar'
import RecenterButton from '../RecenterMap/RecenterButton'


export default function MapSearch(props) {

    const [nearestListings, setNearestListings] = useState([]);
    const [resetStatus, setResetStatus] = useState(false);

    //     const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0});

    //   useEffect(() => {
    //        navigator.geolocation.getCurrentPosition(function (position) {

    //         setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude });

    //     });
    //     }, [])
    useEffect(() => {
        props.setDetails(nearestListings)
    }, [nearestListings])





    return (
        <div className="columns m-0">
            {/* <div className="column is-5" style={{marginRight: '-90px'}}>
            <div  style={{position: 'relative',}}>
            <span style={{position: 'absolute', top: '50%', left: '5%'}}><h3 className="has-text-white has-text-weight-bold">FIND TOP-RATED, CERTIFIED PROS</h3></span>
                <img style={{width: 'auto', height: '90vh'}} src={bannerImg} />
            </div>
            

            
            </div> */}
            <div className="column p-0" style={{ position: 'relative', }}>
                <SearchBar srch={props.landingSearch} styl={{ position: 'absolute', zIndex: 10, top: '5%', left: '5%' }} getListings={setNearestListings} setHeader={props.setHeader} userLocation={props.userLocation} />
                <RecenterButton getResetStatus={setResetStatus}></RecenterButton>
                <Map mapReset={resetStatus} getResetStatus={setResetStatus} plotListings={nearestListings} userLocation={props.userLocation} />

            </div>
        </div>
    )
};