import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar';
import {
  BrowserRouter as Router,
  // Switch,
  // Route,
  // Link
} from "react-router-dom";
const tagManagerArgs = {
  gtmId: 'GTM-MCKLCZX'
}


TagManager.initialize(tagManagerArgs)
hotjar.initialize(2318960, 6);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);


