import React from 'react'
import Header from './Header'
import Results from './Results'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'

export default function SearchResultsDisplay(props) {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
      })
      // const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' })
      // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' })
      // const isTabletOrMobileDevice = useMediaQuery({
      //   query: '(max-device-width: 1224px)'
      // })
      // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      // const isLandscape = useMediaQuery({ query: '(orientation: lanscape)' })
      // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
      
      var resultsList = classNames({
        'column': isDesktopOrLaptop ? true : false
      });
    return (
        <div className="columns pl-6 pr-6 m-0" style={{backgroundColor: '#F4F5F4'}}>
            <div className={resultsList}>
                <Header header={props.header} />
                <Results userLocation={props.userLocation} details={props.details} />
            </div>
        </div>
    )
}
