import React from 'react';
import MyAccount from './MyAccount';
import './Nav.css';
import header from '../../Assets/verticals-header.png'
const TempNav = () => {

    return (
        <nav className="navbar nav-VJM " role="navigation" aria-label="main navigation">
            <div className="navbar-brand ">
                <div className="navbar-item ">
                <a href="/" className="logo">
                <img src={header} alt="VerticalsJM website logo" style={{ maxHeight: '2rem' }} />
                </a>
                    {/* <h2 className="has-text-weight-bold is-size-4 has-text-white pb-0 ml-3"> <a href="/" className="has-text-white logo">VerticalsJM</a></h2> */}
                </div>
            </div>
        </nav>
       
    )
}

export default TempNav;
