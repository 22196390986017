import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import './SearchBar.css'
import useWindowDimensions from '../Hooks/getWindowDimension';
const labelText = `E.g:locksmith`;

export default function SearchBar(props) {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [noInputAlert, setNoInputAlert] = useState("#FFFFFF")
    const [hashTag, setHashTag] = useState([]);
    const [label, setLabel] = useState(labelText);
    var [classN, setClassName] = useState("field has-addons");
    const [inputWidth, setInputWidth] = useState(300);
    const { height, width } = useWindowDimensions();
    const getHashtagsURL = 'https://api.verticalsjm.com/tags';
    const defaultProps = {
        options: hashTag,
        getOptionLabel: (option) => `#${option.tag_name}`,
    };
    const filterOptions = createFilterOptions({
        limit: 5
      });

      useEffect(() => { setInputWidth(width/2)}, [width])
    useEffect(() => { fetch(getHashtagsURL).then(response => response.json()).then((json) => {setHashTag(json);}).catch(err => console.log(err))
    }, [])

    function search() {
        if(selectedCategories.length < 1) {
            props.setSearchInputEmpty(true)
            setLabel("Search for a service here")
            if(inputWidth <= 250) {
                setNoInputAlert("#2c3892")
            }
            setClassName = "field has-addons highlight-search-bar"
            

            window.setTimeout(function(){ props.setSearchInputEmpty(false); setClassName("field has-addons"); setLabel(labelText); setNoInputAlert("#FFFFFF") }, 1500);

        } else {
            props.setSearchInputEmpty(false)
            props.setRedir("/home");
        }
        
    }

    return (
        <div id="search-bar" className="autocomplete-mobile-width " style={props.styl}>
            <div id="search-body" className={classN} >
                <div className="control" >
                    <Autocomplete
                        {...defaultProps}
                        id="auto-highlight"
                        filterOptions={filterOptions}
                        autoHighlight
                        autoSelect={true}
                        multiple
                        size="small"
                        onChange={(event, newValue, reason) => {
                            reason === "select-option" ? setLabel("") : setLabel(labelText);
                            props.setSearch(newValue ?? "");
                            setSelectedCategories(newValue)
                        }}
                        onOpen={(event) => {
                            selectedCategories === "" ? setLabel(labelText) : setLabel("")
                        }}
                        onClose={(event, reason) => {
                            reason === "select-option"  ? setLabel("") : (selectedCategories !== null || selectedCategories !== "") ? setLabel("") : setLabel(labelText);
                        }}
                        onInputChange={(event, value, reason) => {
                            setLabel("")
                        }}
                        limitTags={1}
                        style={{ maxWidth: 300, width: inputWidth, backgroundColor: noInputAlert }}
                        renderInput={(params) => <TextField {...params} label={ inputWidth <= 250 ? "" : label} variant="outlined" />}
                    />
                </div>
                <div className="control" style={{backgroundColor: 'transparent'}}>
                    <button onClick={search} className="button is-info is-medium" style={{ height: 40, backgroundColor: '#F07238', borderRadius: 0 }}>
                        <span data-uk-icon="search"></span>
                    </button>
                </div>
            </div>

        </div>
    )
}
