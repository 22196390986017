import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import './ListingItem.css';
const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#131E3A',
    width: theme.spacing(7),
    height: theme.spacing(7),
  }
}))

export default function ListingItems(props) {
  const classes = useStyles();
  const listingDetails = props.details;
  // console.log(listingDetails)
  return (

    <div id={listingDetails.listing_id} className="card" >
      {/* <div className="card-image">
        <figure className="image is-4by3">
          <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
        </figure>
      </div> */}
      
      <div className="card-content">
        <div className="is-flex is-align-items-center mb-3">
          <Avatar aria-label="recipe" className={classes.avatar}>
            {listingDetails !== '' ? listingDetails.listing_name.charAt(0) : ''}
          </Avatar>
   
          <h5 className="title is-5 ml-2 mt-4 is-align-self-center">{listingDetails.listing_name}</h5>
        </div>




        <div className="content">
          {
            props.userLocation.lat !== 0 ?
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <span className="tag mb-1 is-light" >{listingDetails.distanceAwayInMiles.toFixed(2)} miles away</span>
            <a href={`https://www.google.com/maps?saddr=Current+Location&daddr=${listingDetails.lat},${listingDetails.lng}`}  className="button is-link is-outlined is-small">
            Get Directions
            </a>
            </div>
            :
            <></>
            }

          <address className="subtitle is-5 is-italic">{`${listingDetails.civic_address}, ${listingDetails.city}`} </address>
          {
            listingDetails.listing_description ? 
            <ul data-uk-accordion className="ml-0 pl-0">
            <div className="uk-open">
              <a className="uk-accordion-title" href="#">What do we offer?</a>
              <div className="uk-accordion-content">
                <div className="has-text-justified">
                  {listingDetails.listing_description}
                </div>
              </div>
            </div>
          </ul>
          :
          <></>
          }
          


          <br />
          <div className="mt-1">
            <div className="outer-details">
            <div className="details-icon"><i className="fas fa-phone"></i></div>  <div className="details-text">{listingDetails.contact_num}</div>
            </div>
            <div className="outer-details">{
              listingDetails.contact_num_2 !== ""
                ? <> <div className="details-icon"><i className="fas fa-phone"></i></div>  <div className="details-text">{listingDetails.contact_num_2} </div></>
                : <></>
            }

            </div>
            <div className="outer-details">
              <div className="details-icon"><i className="fas fa-envelope"></i></div>   <div className="details-text">{listingDetails.email_addr}</div>
            </div>
            <div className="outer-details">
              <div className="details-icon"><i className="fas fa-calendar-alt"></i></div> <div className="details-text">{listingDetails.day_of_week}</div>
            </div>
            <div className="outer-details">
              <div className="details-icon"><i className="fas fa-clock"></i></div>  <div className="details-text">{listingDetails.hrs}</div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
