import React, { useState } from "react";
import './TermsOfUse.css';

export default class TermsOfUse extends React.Component {

    render() {

        return (
            <>
            
            <div className="Contain"> 
            <h2><strong>TERMS OF USE AGREEMENT</strong></h2>
            <p>Last Updated: June 03, 2021</p>

            <p>Thank you for visiting verticalsjm.com (the “Site”). The Site is controlled and operated by Discovery Technologies Jamaica, having its principal place of business in Kingston Jamaica.</p>

            <h2><strong>DEFINITIONS</strong></h2> 
            <p>For the purposes of these Terms and Conditions:</p>

            <ul>
                <li><strong>Website or Site</strong>refers to VerticalsJM, accessible from https://www.verticalsjm.com/</li>
                <li><strong>Terms and Conditions</strong>Terms and Conditions (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
                <li><strong>Service</strong> refers to the Website.</li>
                <li><strong>You</strong> means the individual accessing or using the website..</li>

            </ul>

            <h2><strong>USER CONDUCT</strong></h2>
            <p>You agree that you will not:</p>
            <ul>
                <li>use the Website for any fraudulent or unlawful purpose;</li>
                <li>use the Website to defame, abuse, harass, stalk, threaten or otherwise violate the rights of others, including without limitation others' privacy rights; </li>
                <li>impersonate any person or entity, falsely state or otherwise misrepresent your affiliation with any person or entity in connection with the Website;</li>
                <li>reproduce, duplicate, copy, modify, sell, re-sell or exploit any Content or the Sites and Services for any commercial, educational, or any other non-personal purpose or any for any purpose without VerticalsJM’s written consent;</li>
                <li>knowingly provide or submit false or misleading information;</li>
                <li>attempt to gain unauthorized access to the Sites and Services, other user accounts, or other computer systems or networks connected to the Sites and Services;</li>
                <li>access, download, monitor, or copy any information contained on our Sites and Services through artificial means (including but not limited to use any ‘deep-link’, ‘scraper’, ‘robot’, ‘spider’ or other automatic device, program, algorithm or methodology, or any similar or equivalent automatic or manual process, or in any way reproduce or circumvent the navigational structure or presentation of the Sites and Services or any content, to obtain or attempt to obtain any Content, materials, documents or information through any means not purposely made available through the Sites and Services;</li>
                
           </ul>

           <h2><strong>CHANGES TO TERMS</strong></h2>
           <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. By continuing to access or use this website after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the Service.</p>

            <h2><strong>CONTACT US</strong></h2>

            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
            <ul><li>By email: verticalsjmapp@gmail.com</li></ul>
           
            </div>
         </>

        );
    }

}