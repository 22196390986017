import React from 'react'
import recenter from '../../Assets/direction-icon.png'

export default function RecenterButton({getResetStatus}) {

    function resetMap() {
        getResetStatus(true);
    }

    return (
        <div style={{ position: 'absolute', zIndex: 11, top: '90%', left: '5%' }}>
            <img onClick={resetMap} src={recenter} alt="missing" style={{maxHeight: '3rem', maxWidth: '3rem', backgroundColor: '#ef9f7a', padding: '5px', borderRadius: '50%'}}></img>
        </div>
    )
}
