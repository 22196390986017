import React, { useEffect, useRef } from 'react'
import './Map.css'
import L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import mapIcon from '../../Assets/map-icon.png';

// import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

export default function Map({plotListings, userLocation, mapReset, getResetStatus}) {
    plotListings.sort(function(a, b) {
        return parseFloat(a.distanceAwayInMiles) - parseFloat(b.distanceAwayInMiles);
    });
    console.log(plotListings);
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    // const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });
    // L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    /**
     * Reference to the map element that is displayed.
     * Allows you to interact with it without having to do
     * document.getElementByID('mapID)
     */
    const mapRef = useRef();

    /**
     * When the page first loads, this initializes the map,
     * displays it and goes to the users current location.
     * Map is Leaflet
     */
    useEffect(() => {
        /**
         * mapId is the div in which the map is to be 
         * displayed
         */
        const map = L.map('mapId', {
            tap: false,
            center: [0, 0],
            zoom: 8,
            zoomControl: false,
            gestureHandling: true,
            layers: [
                L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                    attribution:
                        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                }),
            ]
        });
        mapRef.current = map;
        // navigator.geolocation.getCurrentPosition(function (position) {

            // setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
            

        // });

        return () => {
            // console.log('cleanup')
            // console.log(map)
            if (map) {
                map.off()
                map.remove();
            }
        }
    }, [])

    useEffect(() => {
        if (mapReset == true) {
            console.log(mapReset);
            console.log(userLocation);
            if (userLocation.lat != 0 || userLocation.lng != 0) {
                mapRef.current.setView([userLocation.lat, userLocation.lng], 12)
            }
        }
        getResetStatus(false);
        console.log(mapReset);
    }, [mapReset])

    useEffect(() => {
        if(userLocation.lat !== 0) {
            var userIcon = new L.Icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowSize: [41, 41]
              });
            let userPos = [userLocation.lat, userLocation.lng]
            const userPositionLayer = L.layerGroup();
            const userPositionMarker = L.marker([userLocation.lat, userLocation.lng], {icon: userIcon}).bindPopup('Your Location');
            userPositionMarker.on('click', function(e) {this.openPopup();});
            if (!userPositionLayer.hasLayer(userPositionMarker)) {
                // console.log('adding layer to map')
                userPositionLayer.addLayer(userPositionMarker);
                userPositionLayer.addTo(mapRef.current);
                mapRef.current.setView([userLocation.lat, userLocation.lng], 12)
            }

        } else {

            mapRef.current.setView([18.1155174, -77.2760026], 9)
        }
        

    }, [userLocation])
    useEffect(() => {
        let listingLayer = L.layerGroup();
        let customOptions = {
            'closeButton' : false,
            'className': 'customPop'
        }
        /**
         * Adds a marker on the map for each listing returned
         * from the db
         */
         var vertical = new L.Icon({
            iconUrl: 'https://verticalsjm-assets.s3.us-east-2.amazonaws.com/marker.png',
            shadowUrl: 'https://verticalsjm-assets.s3.us-east-2.amazonaws.com/marker.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [0, 0]
          });
        //   console.info(mapIcon)
        plotListings.forEach((coords) => {
            let customPopup = `<div>
            <div style="width:100%;">
            <h6 class="title is-6 pl-2 pt-2 pb-2 pr-2 mb-0 has-text-white " 
            style="background-color: #131e3a;">
            ${coords.listing_name}
            </h5>
            </div>
            <div class="p-2 is-flex is-flex-direction-column">
            <address class="mb-2 is-size-6 is-italic">
            ${coords.civic_address},
            ${coords.city}
            </address>
            <div>
            <a class="has-text-centered hes-text-white has-text-weight-bold" href="https://www.google.com/maps?saddr=Current+Location&daddr=${coords.lat},${coords.lng}" target="_blank" rel="noopener noreferrer">Get Directions</a>
            </div>
            <address class="is-size-7">
            ${coords.contact_num !== "" ? `tele: <a href="tel:${coords.contact_num}" alt="First contact number">${coords.contact_num}</a></br>` : ``}
            ${coords.contact_num_2 !== "" ? `tele: <a href="tel:${coords.contact_num_2}" alt="Second contact number">${coords.contact_num_2}</a></br>` : ``}
            ${coords.mobile_num !== "" ? `mobile: <a href="tel:${coords.mobile_num}" alt="Mobile number">${coords.mobile_num}</a></br>` : ``}
            ${coords.email_addr !== "" ? `email: <a href="mailto:${coords.email_addr}" alt="Email Addreaa">${coords.email_addr}</a>` : ``}
            
            </address>
            <a class="has-text-centered has-text-white has-text-weight-bold " style="background-color: #f07238" href="#${coords.listing_id}" alt="Link that takes you to extra information about listing: ${coords.listing_name}">Click for more information </br> <i class="fas fa-chevron-down"></i></a>
            </div>

            
            </div>`

            let listingMarker = L.marker([coords.lat, coords.lng], {icon: vertical}).bindPopup(customPopup, customOptions);
            listingMarker.on('click', function(e) {this.openPopup();});
            if (!listingLayer.hasLayer(listingMarker)) {
                listingLayer.addLayer(listingMarker);
                // console.log('added a layer!')
            }
        });
        listingLayer.addTo(mapRef.current);
        return () => {
            // console.log('listing cleanup')
            listingLayer.remove();

        }


    }, [plotListings])


    if (!userLocation) {
        return <p>Waiting...</p>;
      }
    return (

        <div className=" has-text-centered "  id="mapId" style={{zIndex: 1}}>

        </div>

    )
}
