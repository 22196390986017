import React, { useState, useEffect } from 'react';
import './App.css'
import MapSearch from './MapSearch-Section/MapSearch'
import Footer from './Navbar/Footer';
import TempNav from './Navbar/TempNav'
import SearchResultsDisplay from './SearchResults/SearchResultsDisplay'


import {
  // BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  Redirect
} from "react-router-dom";
import LandingPage from './Navigation/LandingPage';
// import ScrollTopArrow from './Scroll/ScrollTopArrow';
 //import FeedSub from './Feedback-Submission/FeedSub';
import ScrollToTop from "react-scroll-to-top";
import PrivatePolicy from './Private Policy/PrivatePolicy';
import TermsOfUse from './TermsAndCondition/TermsOfUse';
import TransitionToTop from './TransitionToTop';



function App() {
  const [searchHeader, setSearchHeader] = useState({ searchTerm: "", count: 0 })
  const [listingDetails, setListingDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("")
  const [redir, setRedir] = useState("")

  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0});
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {

            setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
            

        });
  }, [])

  return (
    <Switch>
     <Route exact path="/">
        <LandingPage setRedir={setRedir} setSearchTerm={setSearchTerm} />
        {redir !== "" ? <Redirect to={redir} /> : <></>}
     </Route>

  <Route path="/Privacy">
  <TransitionToTop />
  <ScrollToTop style={{marginBottom: "5%"}} smooth color="#6f00ff" />
  <TempNav  />
        <PrivatePolicy/>

        <Footer  />
  </Route>
 
  <Route path="/Terms">
  <TransitionToTop />
  <ScrollToTop style={{marginBottom: "5%"}} smooth color="#6f00ff" />
  <TempNav  />
        <TermsOfUse/>
        <Footer  />
  </Route>
  
  <Route path="/home">
        <>
      
        <ScrollToTop style={{marginBottom: "5%"}} smooth color="#6f00ff" />
          <TempNav  />
          <MapSearch userLocation={userLocation} landingSearch={searchTerm} setHeader={setSearchHeader} setDetails={setListingDetails} />
          {
            searchHeader.count === 0
              ? <div></div>
              : <SearchResultsDisplay userLocation={userLocation} details={listingDetails} header={searchHeader} />
          }
          {/* <Testimonial /> */}
          {/* <ScrollTopArrow /> */}
          {/* <FeedSub /> */}
          {/* <Feedback
          style={{zIndex:'1000', marginBottom:'5%', position:'fixed'}}
        position="right"
        numberOfStars={5}
        headerText="Feedback"
        bodyText="How was your experience with our website?"
        buttonText="Feedback"
        handleClose={() => console.log("handleclose")}
        handleSubmit={data =>
          fetch("xxxxxx", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            method: "POST", // or 'PUT'
            body: JSON.stringify(data)
          })
            .then(response => {
              if (!response.ok) {
                return Promise.reject(
                  "Our servers are having issues! We couldn't send your feedback!"
                );
              }
              response.json();
            })
            .then(() => {
              alert("Success!");
            })
            .catch(error => {
              alert(
                "Our servers are having issues! We couldn't send your feedback!",
                error
              );
            })
        }
        handleButtonClick={() => console.log("handleButtonClick")}
         /> */}
        <Footer />

        </>
      </Route>

  <Route path="/">

 </Route>

    </Switch>


   
  )
}

export default App;
