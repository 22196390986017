import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import './SearchBar.css'
const labelText = `E.g:locksmith`;

export default function SearchBar(props) {
    
    const [selectedCategories, setSelectedCategories] = useState([props.srch[0] ?? ""])
    const [hashTag, setHashTag] = useState([]);
    const [label, setLabel] = useState(labelText);
    const getNearestServicesUrl = 'https://api.verticalsjm.com/getlistings';
    const getHashtagsURL = 'https://api.verticalsjm.com/tags';
    const defaultProps = {
        options: hashTag,
        getOptionLabel: (option) => `#${option.tag_name}`
    };
    let valProps = {
        ...(selectedCategories[0] !== "" && {value: selectedCategories})
    }
    const filterOptions = createFilterOptions({
        limit: 5
    });
    
    useEffect(() => {
        /**
         * Sets the state that contains the list of available hashtags
         * (plumbing, faucet etc..) to display to the user
         */
        fetch(getHashtagsURL)
            .then(response => response.json())
            .then((json) => {

                setHashTag(json);
            })
            .catch(err => console.log(err))
    }, [])




    function search() {
        // navigator.geolocation.getCurrentPosition(function (position) {

            let body = {
                "tag_id": selectedCategories === null ? "" : selectedCategories,
                "user_lat": props.userLocation.lat === 0 ?  props.userLocation.lat : props.userLocation.lat,
                "user_lng": props.userLocation.lng === 0 ?  props.userLocation.lng : props.userLocation.lng
            }

            axios.post(getNearestServicesUrl, body)
                .then(function (response) {
                    if (response.status === 200) {

                        if (response.data.length > 0 && selectedCategories !== null) {

                            props.setHeader({ searchTerm: "", count: response.data.length });
                        } else {
                            props.setHeader({ searchTerm: "", count: 0 });

                        }
                        props.getListings(response.data);
                    }

                })
                .catch(function (error) {
                    console.info(error)
                });
        // });
    }
    useEffect(() => {
        search()
    }, [props.srch])

    
      useEffect(() => {
        if (!props.userLocation) {
            return <p>Waiting...</p>;
          }
      }, [props.userLocation])

    return (
        <div id="search-bar" className="autocomplete-mobile-width " style={props.styl}>
            <div id="search-body" className="field has-addons" >
                <div className="control">
                    <Autocomplete
                        {...defaultProps}
                        {...valProps}
                        id="auto-highlight"
                        filterOptions={filterOptions}
                        autoHighlight
                        autoSelect={true}
                        multiple
                        size="small"
                        onChange={(event, newValue, reason) => {
                            reason === "select-option" ? setLabel("") : setLabel(labelText)
                            setSelectedCategories(newValue ?? "")
                        }}
                        onOpen={(event) => {
                            selectedCategories === "" ? setLabel(labelText) : setLabel("")
                        }}
                        onClose={(event, reason) => {
                            reason === "select-option" ? setLabel("") : (selectedCategories !== null || selectedCategories !== "") ? setLabel("") : setLabel(labelText);
                        }}
                        onInputChange={(event, value, reason) => {
                            setLabel("")

                        }}
                        limitTags={1}
                        // value={props.srch}
                        // className="autocomplete-mobile-width"
                        style={{ width: 250, backgroundColor: '#FFFFFF', }}
                        renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
                    />
                </div>
                <div className="control" style={{ backgroundColor: 'transparent' }}>
                    <span onClick={search} className="button is-info is-medium" style={{ height: 40, backgroundColor: '#F07238', borderRadius: 0 }}>
                        <span data-uk-icon="search"></span>
                    </span>
                </div>
            </div>

        </div>
    )
}
